.App {
  text-align: center;
}

.title-icon {
  float: right;
  margin: 3px 0px 2px 2px;
}

.window-title {
  background: linear-gradient(to right, #010086, #010086, #02014f) !important;
}

td {
  vertical-align: middle !important;
}

.footer {
  display: block; 
  height: 31px;
  width: 98%;
  position: absolute !important;
  bottom: 5px !important;
  padding-left: 10px;
  padding-top: 2px;
}
